/**
 *
 * Header - desktop
 * 
 */

// #header
.header-inner
	flex()
	justify-content space-between
	padding 20px 0 24px
	max-height initial
	&.opened
		max-height initial

// Button
#navToggleBtn
	display none

// Global Navigation
.global-nav
	margin-top 6px

.global-nav-ul
	justify-content flex-start
	> li
		&:first-child
			border-left 1px solid $gray
		&:last-child
			border-right 1px solid $gray