/**
 *
 * Front Page - desktop
 * 
 */
 
/**
 * Pickup
 */
.pickup-inner
	text-align left

.pickup-pic
	width (100/3*2)%

.pickup-texts
	width (100/3)%

.pickup-h3
	margin 36px 0 48px
	padding 0 0 32px
	width 100%
	max-width initial
	&:after
		left 0
		margin-left 0

.pickup-ttl
	line-height 70px
	margin 0 0 54px -192px
	z-index 10
	> a
		transition color .3s
		&:hover
			color $green
		> span
			background-color #fff
			padding 11px 12px 10px