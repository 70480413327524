/**
 *
 * Desktop
 *
 */

@charset "UTF-8"
@import "common/variables"
@import "common/mixins"

@import "base/desktop"
@import "typography/desktop"

// Modules
@import "module/button/desktop"
@import "module/card/desktop"
@import "module/carousel/desktop"
@import "module/form/desktop"
@import "module/grid/desktop"
@import "module/list/desktop"
@import "module/table/desktop"

// Layout
@import "layout/header/desktop"
@import "layout/sidebar/desktop"
@import "layout/footer/desktop"

// Specific CSS for each page
@import "page/all/desktop"
@import "page/index/desktop"
@import "page/post/desktop"