/**
 *
 * Base - desktop
 * 
 */

// Display Style
.desktop
	display block
	&.ib
		display inline-block
	&.inline
		display inline
	&.table
		display table
	&.table-cell
		display table-cell