/**
 *
 * Post - desktop
 * 
 */

/**
 * index & archive
 */
article.flex-4
	width (100/3)%